var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "inqli-button",
        {
          attrs: { width: _vm.width },
          on: { click: _vm.openDeleteFileDialog }
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _c("confirm-dialog", {
        attrs: {
          title: _vm.dialogTitle,
          buttonList: _vm.dialogButtonList,
          description: _vm.dialogDescription,
          dialogKey: _vm.dialogKey
        },
        model: {
          value: _vm.displayDialog,
          callback: function($$v) {
            _vm.displayDialog = $$v
          },
          expression: "displayDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }