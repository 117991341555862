<template>
  <div>
    <inqli-button @click="openDeleteFileDialog" :width="width">{{
      title
    }}</inqli-button>
    <confirm-dialog
      v-model="displayDialog"
      :title="dialogTitle"
      :buttonList="dialogButtonList"
      :description="dialogDescription"
      :dialogKey="dialogKey"
    />
  </div>
</template>
<script>
import Button from "../buttons/OutlineButton.vue";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog.vue";
import sendRequest from "../../../app/utils/send-request-helper.js";

export default {
  name: "DeleteFileButton",
  components: {
    "inqli-button": Button,
    "confirm-dialog": ConfirmDialog,
  },
  data() {
    return {
      displayDialog: false,
      dialogTitle: "",
      dialogDescription: "",
      dialogButtonList: [
        {
          label: "Accept",
          color: "inqliGreen",
          argument: "'",
          onClick: null,
        },
        {
          label: "Cancel",
          color: "inqliRed",
          argument: null,
          onClick: () => (this.displayDialog = false),
        },
      ],
      dialogKey: "",
    };
  },
  props: {
    title: {
      type: String,
      default: "Delete image",
    },
    fileType: {
      type: String,
      default: "image",
    },
    resourceId: {
      type: String,
      required: true,
    },
    resourceType: {
      type: String,
      required: true,
    },
    fileUrl: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "",
    },
  },
  computed: {
    fileName() {
      return this.fileUrl.split("/").pop();
    },
  },
  methods: {
    openDeleteFileDialog() {
      this.dialogTitle = `Delete ${this.fileType}`;
      this.dialogDescription = `Are you sure you want to delete this ${this.fileType}?`;
      this.dialogButtonList[0] = {
        ...this.dialogButtonList[0],
        onClick: this.deleteFile,
        label: "Delete",
      };
      this.dialogKey = this.fileName;
      this.displayDialog = true;
    },
    async deleteFile() {
      try {
        await sendRequest({
          url: `${process.env.VUE_APP_URL}/services/images/${this.fileName}?resource_id=${this.resourceId}&resource_type=${this.resourceType}`,
          method: "DELETE",
          isAuth: true,
        });
        this.$store.dispatch(
          "callAlert",
          `${this.fileType} deleted successfully`
        );
        this.$emit("delete");
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          `An error occurred while deleting ${this.fileType}.`
        );
      }
      this.displayDialog = false;
    },
  },
};
</script>
